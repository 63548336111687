<template>
  <div class="NotFound py-20">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12">

          <h1 class="text-center">
            404
          </h1>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

</style>
